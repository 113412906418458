@import "./styles/font.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  overflow-x: hidden;
} */

body {
  @apply font-agrandir;
}

/* body {
  @apply font-serif;
  font-family: "Times New Roman"; 
}  */
.text-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  position: relative;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: -8px 0 0 -8px;
  width: 16px;
  aspect-ratio: 1;
  background: #3fb8af;
  animation: l2-1 2s infinite, l2-2 1s infinite;
}
.loader:after {
  background: #ff3d7f;
  animation-delay: -1s, 0s;
}
@keyframes l2-1 {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 100%;
    left: 0;
  }
  50% {
    top: 100%;
    left: 100%;
  }
  75% {
    top: 0;
    left: 100%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes l2-2 {
  40%,
  50% {
    transform: rotate(0.25turn) scale(0.5);
  }
  100% {
    transform: rotate(0.5turn) scale(1);
  }
}
.category-slider .slick-slide {
  padding: 0 10px; /* Adjust space between items */
}

.category-slider .slick-prev,
.category-slider .slick-next {
  color: white; /* Customize navigation arrows */
}

.category-slider .slick-prev:hover,
.category-slider .slick-next:hover {
  color: #00aaff; /* Color when hovering over arrows */
}
.card1 {
  width: 200px;
  height: 400px;
  margin: 1rem auto;
  perspective: 1000px;
  cursor: pointer;
}
.hide-scrollbarr::-webkit-scrollbar {
  display: none;
}

.hide-scrollbarr {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.card1-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card1:hover .card1-inner {
  transform: rotateY(180deg);
}

.card1-front,
.card1-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.card1-front {
  background: white;
  border: 1px solid #e5e7eb;
}

.card1-back {
  background: white;
  border: 1px solid #e5e7eb;
  transform: rotateY(180deg);
}
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% + 100vw));
  }
}

.animate-scroll {
  animation: scroll 3s linear infinite;
  animation-play-state: running;
}

.animate-scroll:hover {
  animation-play-state: paused;
}

.loader1 {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}
.loader1::before,
.loader1::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}
.loader1::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.loader1::after {
  margin: 8px;
}
@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}
/* Apply a custom scrollbar with 4px width */
.custom-scrollbar::-webkit-scrollbar {
  width: 4px; /* Set width to 4px */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 2px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

