@font-face {
    font-family: 'Agrandir';
    src: url('../assets/fonts/Agrandir-Regular.woff2') format('woff2');
         /* url('./assets/fonts/Agrandir-Regular.woff') format('woff'); */
    font-weight: normal; /* Adjust as needed */
    font-style: normal;  /* Adjust as needed */
  }

  body {
    font-family: 'Agrandir', sans-serif;
  }
  
  